<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="500px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :modal-append-to-body="true"
            :append-to-body="true"
            :before-close="handleClose">
        <el-form size="small" :model="inputForm" :rules="dataRule" ref="inputForm" label-width="120px">
            <el-form-item label="所属类型" prop="thematicType">
                <el-select v-model="inputForm.thematicType"
                           placeholder="请选择所属类型"
                           style="width: 100%"
                           clearable>
                    <el-option
                            v-for="item in this.$dictUtils.getDictList('thematic_type')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="专题库名称" prop="thematicName">
                <el-input v-model.trim="inputForm.thematicName" clearable maxlength="20"
                          placeholder="请输入专题库名称(限20字)"></el-input>
            </el-form-item>
            <el-form-item label="专题库归属" prop="ownership">
                <el-radio-group v-model="inputForm.ownership">
                    <el-radio :label="'0'">全馆</el-radio>
                    <el-radio :label="'1'">部门选择</el-radio>
                    <el-radio :label="'2'">个人选择</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item v-if="inputForm.ownership == 1" label="部门选择" prop="department">
                <div style="height: 100px;overflow-y: auto">
                    <el-tree node-key="id" ref="tree" :data="treeData" class="treeDateCls"
                             show-checkbox
                             :props="defaultProps" @check="selRecord">
                        <div slot-scope="{ node, data }">
                            <el-tooltip class="item" effect="dark" :content="node.label" placement="top-start">
                                <div class="omit">{{ node.label }}</div>
                            </el-tooltip>
                        </div>
                    </el-tree>
                </div>
            </el-form-item>
            <el-form-item v-if="inputForm.ownership == 2" label="人员选择" prop="personnel">
                <el-select
                        style="width: 100%;"
                        v-model="inputForm.personnel"
                        filterable
                        remote
                        multiple
                        placeholder="请选择人员"
                        :remote-method="remoteMethod"
                        :loading="personnelLoading">
                    <el-option
                            v-for="item in personnelList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="专题库建设描述" prop="description">
                <el-input :rows="5" type="textarea"
                          v-model.trim="inputForm.description"
                          placeholder="请输入专题库建设描述(限500字)"
                          maxlength="500" clearable></el-input>
            </el-form-item>
        </el-form>
        <div class="text_center">
            <el-button size="small" @click="handleClose()">取消</el-button>
            <el-button size="small" type="primary" @click="suresData()" v-no-more-click>保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "addLibrary",
    data() {
        return {
            title: '新增专题库',
            dialogVisible: false,
            inputForm: {
                thematicType: '',
                thematicName: '',
                ownership: '0',
                description: '',
                department: [],
                personnel: [],
            },
            dataRule: {
                thematicType: [
                    {required: true, message: '请选择所属类型', trigger: 'change'}
                ],
                thematicName: [
                    {required: true, message: '请输入专题库名称', trigger: 'blur'}
                ],
                department: [
                    {type: 'array', required: true, message: '请选择部门', trigger: 'blur'}
                ],
                personnel: [
                    {type: 'array', required: true, message: '请选择人员', trigger: 'blur'}
                ],
            },
            defaultProps: {
                children: 'children',
                label: 'departmentName'
            },
            treeData: [],

            personnelLoading:false,
            personnelList:[],
        }
    },
    mounted() {
        this.$axios(this.api.auth.getSysDepartment, {}, "post").then((data) => {
            if (data && data.status) {
                this.treeData = data.data
                if (this.treeData == 2){
                    this.inputForm.personnel = []
                }
                if (this.treeData == 1){
                    this.inputForm.department = []
                }
            }
        });
    },

    methods: {
        init(num,row) {
            this.remoteMethod('')
            if (num == 2) {
                this.title = '修改专题库'
                this.$axios(this.api.original.thematiclibraryGetById + row.id, {}, "get").then((data) => {
                    if (data && data.status) {
                        this.inputForm = data.data
                        this.dialogVisible = true
                        if(!this.inputForm.department){
                            this.$set(this.inputForm,'department',[])
                        }
                        if(!this.inputForm.personnel){
                            this.$set(this.inputForm,'personnel',[])
                        }
                        this.$nextTick(()=>{
                            if (this.inputForm.ownership == 1 && this.inputForm.thematicLibraryAuthorities){
                                let selectId = this.inputForm.thematicLibraryAuthorities.map(item=>{
                                    return item.ownershipId
                                })
                                this.$refs.tree.setCheckedKeys(selectId);
                                this.$set(this.inputForm,'department',selectId)
                            }
                            if (this.inputForm.ownership == 2 && this.inputForm.thematicLibraryAuthorities){
                                let selectId = this.inputForm.thematicLibraryAuthorities.map(item=>{
                                    return item.ownershipId
                                })
                                this.$set(this.inputForm,'personnel',selectId)
                            }
                        })
                    }
                });
            }else {
                this.title = '新增专题库'
                this.dialogVisible = true
            }
        },

        suresData() {
            console.log(this.inputForm.department)


            let thematicLibraryAuthorities = []
            let seData = JSON.parse(JSON.stringify(this.inputForm))
            if (this.inputForm.ownership == '1'){
                seData.department.forEach(item=>{
                    thematicLibraryAuthorities.push(
                        {
                            ownershipId:item,
                        }
                    )
                })
            }
            if (this.inputForm.ownership == '2'){
                seData.personnel.forEach(item=>{
                    thematicLibraryAuthorities.push(
                        {
                            ownershipId:item,
                        }
                    )
                })
            }
            this.$refs.inputForm.validate((valid) => {
                if (valid) {
                    if (this.inputForm.id){
                        this.$axios(this.api.original.thematiclibraryUpdateById, {
                            id:this.inputForm.id,
                            thematicType:seData.thematicType,
                            thematicName:seData.thematicName,
                            ownership:seData.ownership,
                            description:seData.description,
                            thematicLibraryAuthorities,
                        }, "put").then((data) => {
                            if (data && data.status) {
                                this.$message.success('修改成功')
                                this.$emit('refreshList')
                                this.handleClose()
                            } else {
                                this.$message.error(data.msg)
                            }
                        });
                    }else {
                        this.$axios(this.api.original.thematiclibrarySave, {
                            thematicType:seData.thematicType,
                            thematicName:seData.thematicName,
                            ownership:seData.ownership,
                            description:seData.description,
                            thematicLibraryAuthorities,
                        }, "post").then((data) => {
                            if (data && data.status) {
                                this.$message.success('保存成功')
                                this.$emit('refreshList')
                                this.handleClose()
                            } else {
                                this.$message.error(data.msg)
                            }
                        });
                    }
                }
            })
        },

        // 选择档案分类
        selRecord(data, checked, type) {
            if (checked) {
                this.inputForm.department = []
                this.inputForm.department = checked.checkedNodes.map(item => {
                    return item.id
                })
            }
        },

        //边打边搜
        remoteMethod(query) {
            this.personnelLoading = true;
            this.$axios(this.api.collection.searchUserByLike, {username: query}, 'get').then(data => {
                if (data.status) {
                    this.personnelList = data.data
                    this.personnelLoading = false;
                }
            })
        },

        handleClose() {
            this.inputForm = {
                thematicType: '',
                thematicName: '',
                ownership: '0',
                description: '',
                department: [],
                personnel: [],
            }
            this.$refs.inputForm.resetFields()
            this.dialogVisible = false
        },
    },
}
</script>

<style scoped>

</style>
